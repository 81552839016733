"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.environment = void 0;
var environment = {
  /*
      default values
  */
  backend_url: 'http://equal.local',
  lang: 'fr',
  locale: 'fr'
};
exports.environment = environment;
var _default = environment;
exports.default = _default;