"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.PackClass = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

/**
 * 
 */
var PackClass = // (ex. PDT, AT)
// (ex. Duration: 15 minutes)
// (ex. Program Development Training, Awareness Training)
function PackClass(id, name, // (ex. PDT, AT)
subtitle, // (ex. Duration: 15 minutes)
title, // (ex. Program Development Training, Awareness Training)
description) {
  (0, _classCallCheck2.default)(this, PackClass);
  (0, _defineProperty2.default)(this, "id", void 0);
  (0, _defineProperty2.default)(this, "name", void 0);
  (0, _defineProperty2.default)(this, "subtitle", void 0);
  (0, _defineProperty2.default)(this, "title", void 0);
  (0, _defineProperty2.default)(this, "description", void 0);
  this.id = id;
  this.name = name;
  this.subtitle = subtitle;
  this.title = title;
  this.description = description;
};

exports.PackClass = PackClass;
var _default = PackClass;
exports.default = _default;