"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContextService = exports.ApiService = void 0;

var _ApiService2 = _interopRequireDefault(require("./ApiService"));

var _ContextService2 = _interopRequireDefault(require("./ContextService"));

/**
 * Singleton instances
 */
var ApiService = new _ApiService2.default();
exports.ApiService = ApiService;
var ContextService = new _ContextService2.default();
exports.ContextService = ContextService;