"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "$", {
  enumerable: true,
  get: function get() {
    return _jquery.default;
  }
});

var _jquery = _interopRequireDefault(require("jquery"));